import * as React from 'react'
import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'

// Update the path to your Link Resolver
import { linkResolver } from '../utils/linkResolver'

// Update the path to your Pages and Templates
import PageTemplate from '../templates/page'

const NotFoundPage = () => {
  return (
    <div>
      <h1>Not found</h1>
    </div>
  )
}

export default withPrismicUnpublishedPreview(
  NotFoundPage, 
  [
    {
      repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
      linkResolver,
      componentResolver: componentResolverFromMap({
         page: PageTemplate,
      }),
    },
  ],
)